const createDataModel = (activity) => ({
  media: {
    images: {
      xs: {
        alt: activity.altText,
        renditions: {
          original: {
            src: activity.xs,
            width: 2000,
            height: 1462,
          },
          wide: null,
          vertical: null,
        },
        imageType: 'jpg',
      },
      sm: {
        alt: activity.altText,
        renditions: {
          original: {
            src: activity.sm,
            width: 2000,
            height: 1034,
          },
          wide: null,
          vertical: null,
        },
        imageType: 'jpg',
      },
      md: {
        alt: activity.altText,
        renditions: {
          original: {
            src: activity.md,
            width: 2000,
            height: 1076,
          },
          wide: null,
          vertical: null,
        },
        imageType: 'jpg',
      },
      lg: {
        alt: activity.altText,
        renditions: {
          original: {
            src: activity.lg,
            width: 4000,
            height: 1825,
          },
          wide: null,
          vertical: null,
        },
        imageType: 'jpg',
      },
    },
    alt: activity.altText,
    useResizing: true,
  },
  palette: {
    content: 'brand-inverse',
    surface: 'brand',
  },
  content: {
    heading: {
      text: activity.title,
      srOnly: false,
      level: 'h1',
      size: 'md-light',
    },
    body: activity.text,
    subheading: '',
    eyebrow: '',
    lockup: activity.lockup,
    actions: {
      defaultActions: [
        {
          text: activity.button,
          target: activity.link,
        },
      ],
      javascriptActions: [],
      alternateLink: {
        href: '',
        text: '',
      },
      allSecondaryButtons: false,
    },
    config: {
      palette: {
        content: 'brand-inverse',
        surface: 'brand',
      },
      effect: null,
      secondaryButtonsOnly: false,
      height: 'Mid',
      anchor: 'Bottom Center',
      anchorMobile: 'Bottom Center',
      contentSize: 'XL Light',
      copyAlignment: 'Center',
      copyAlignmentDesktop: 'Center',
      storyRowCount: null,
      storyStackPosition: null,
      contentAlign: {
        xs: 'center',
      },
    },
  },
  bannerComponentProps: {
    contentPosition: {
      xs: 'center bottom',
    },
    bannerPosition: {
      xs: 'center center',
      lg: 'center center',
    },
    bannerHeight: 'mid',
    bannerGradient: null,
  },
  cards: {
    'card-0': {
      media: {
        images: {
          xs: {
            caption: null,
            alt: activity.cardAAltText,
            renditions: {
              original: {
                src: activity.cardAImage,
                width: 2000,
                height: 1652,
              },
              wide: null,
              vertical: null,
            },
            imageType: 'jpg',
          },
          sm: {
            caption: null,
            alt: activity.cardAAltText,
            renditions: {
              original: {
                src: activity.cardAImage,
                width: 2000,
                height: 1652,
              },
              wide: null,
              vertical: null,
            },
            imageType: 'jpg',
          },
          md: {
            caption: null,
            alt: activity.cardAAltText,
            renditions: {
              original: {
                src: activity.cardAImage,
                width: 2000,
                height: 1652,
              },
              wide: null,
              vertical: null,
            },
            imageType: 'jpg',
          },
          lg: {
            caption: null,
            alt: activity.cardAAltText,
            renditions: {
              original: {
                src: activity.cardAImage,
                width: 2000,
                height: 1652,
              },
              wide: null,
              vertical: null,
            },
            imageType: 'jpg',
          },
        },
        alt: activity.cardAAltText,
        useResizing: false,
        renderedSizes: {
          mobile: '100vw',
          desktop: '550px',
        },
      },
      heading: activity.cardATitle,
      body: activity.cardAText,
      target: activity.cardALink,
      badge: {
        text: null,
        theme: 'brand-inverse',
        accentColor: '',
      },
      palette: {
        content: 'primary',
      },
    },
    'card-1': {
      media: {
        images: {
          xs: {
            caption: null,
            alt: activity.cardBAltText,
            renditions: {
              original: {
                src: activity.cardBImage,
                width: 2000,
                height: 1652,
              },
              wide: null,
              vertical: null,
            },
            imageType: 'jpg',
          },
          sm: {
            caption: null,
            alt: activity.cardBAltText,
            renditions: {
              original: {
                src: activity.cardBImage,
                width: 2000,
                height: 1652,
              },
              wide: null,
              vertical: null,
            },
            imageType: 'jpg',
          },
          md: {
            caption: null,
            alt: activity.cardBAltText,
            renditions: {
              original: {
                src: activity.cardBImage,
                width: 2000,
                height: 1652,
              },
              wide: null,
              vertical: null,
            },
            imageType: 'jpg',
          },
          lg: {
            caption: null,
            alt: activity.cardBAltText,
            renditions: {
              original: {
                src: activity.cardBImage,
                width: 2000,
                height: 1652,
              },
              wide: null,
              vertical: null,
            },
            imageType: 'jpg',
          },
        },
        alt: activity.cardBAltText,
        useResizing: false,
        renderedSizes: {
          mobile: '100vw',
          desktop: '550px',
        },
      },
      heading: activity.cardBTitle,
      body: activity.cardBText,
      target: activity.cardBLink,
      badge: {
        text: null,
        theme: 'brand-inverse',
        accentColor: '',
      },
      palette: {
        content: 'primary',
      },
    },
    'card-2': {
      media: {
        images: {
          xs: {
            caption: null,
            alt: activity.cardCAltText,
            renditions: {
              original: {
                src: activity.cardCImage,
                width: 2000,
                height: 1652,
              },
              wide: null,
              vertical: null,
            },
            imageType: 'jpg',
          },
          sm: {
            caption: null,
            alt: activity.cardCAltText,
            renditions: {
              original: {
                src: activity.cardCImage,
                width: 2000,
                height: 1652,
              },
              wide: null,
              vertical: null,
            },
            imageType: 'jpg',
          },
          md: {
            caption: null,
            alt: activity.cardBAltText,
            renditions: {
              original: {
                src: activity.cardCImage,
                width: 2000,
                height: 1652,
              },
              wide: null,
              vertical: null,
            },
            imageType: 'jpg',
          },
          lg: {
            caption: null,
            alt: activity.cardCAltText,
            renditions: {
              original: {
                src: activity.cardCImage,
                width: 2000,
                height: 1652,
              },
              wide: null,
              vertical: null,
            },
            imageType: 'jpg',
          },
        },
        alt: activity.cardCAltText,
        useResizing: false,
        renderedSizes: {
          mobile: '100vw',
          desktop: '550px',
        },
      },
      heading: activity.cardCTitle,
      body: activity.cardCText,
      target: activity.cardCLink,
      badge: {
        text: null,
        theme: 'brand-inverse',
        accentColor: '',
      },
      palette: {
        content: 'primary',
      },
    },
  },
  hasCards: true,
  videoFocalPoint: null,
});

const activities = [
  {
    name: 'hot',
    altText: 'Two people smile for the camera while on a run with a dog.',
    eyebrow: null,
    lockup: {
      images: {
        xs: {
          caption: null,
          alt: 'HOKA, R E I Co-op, and Brooks',
          attribution: null,
          renditions: {
            original: {
              src: 'https://www.rei.com/dam/20978579_warm-weather-run-lockup-xs.svg',
              width: 159,
              height: 39,
            },
            wide: null,
            vertical: null,
          },
          imageType: 'svg',
        },
        sm: {
          caption: null,
          alt: 'HOKA, R E I Co-op, and Brooks',
          attribution: null,
          renditions: {
            original: {
              src: 'https://www.rei.com/dam/20978579_warm-weather-run-lockup-xs.svg',
              width: 159,
              height: 39,
            },
            wide: null,
            vertical: null,
          },
          imageType: 'svg',
        },
        md: {
          caption: null,
          alt: 'HOKA, R E I Co-op, and Brooks',
          attribution: null,
          renditions: {
            original: {
              src: 'https://www.rei.com/dam/20978577_warm-weather-run-lockup-lg.svg',
              width: 227,
              height: 55,
            },
            wide: null,
            vertical: null,
          },
          imageType: 'svg',
        },
        lg: {
          caption: null,
          alt: 'HOKA, R E I Co-op, and Brooks',
          attribution: null,
          renditions: {
            original: {
              src: 'https://www.rei.com/dam/20978577_warm-weather-run-lockup-lg.svg',
              width: 227,
              height: 55,
            },
            wide: null,
            vertical: null,
          },
          imageType: 'svg',
        },
      },
      alt: 'HOKA, R E I Co-op, and Brooks',
      useResizing: false,
    },
    lg: 'https://www.rei.com/dam/20978571_warm-weather-run-lead-img-lg_web_lg.jpeg',
    md: 'https://www.rei.com/dam/20978572_warm-weather-run-lead-img-md_web_lg.jpeg',
    sm: 'https://www.rei.com/dam/20979570_warm-weather-run-lead-img-sm_web_lg.jpeg',
    xs: 'https://www.rei.com/dam/20978570_warm-weather-run-lead-img-xs_web_lg.jpeg',
    title: 'Warm up for training season',
    text: 'We’ve got the gear, expertise and energy gels to get you there.',
    button: 'Shop running',
    link: '/c/running',
    cardAImage: 'https://www.rei.com/dam/20979702_crd-4-hoka-img-1_web_lg.jpeg',
    cardATitle: 'Running shoes',
    cardAText: '',
    cardAAltText: 'A person holds up a HOKA Bondi 9 for the camera.',
    cardALink: '/c/running-shoes',
    cardBImage: 'https://www.rei.com/dam/20979700_crd-4-run-clothing-img-2_web_lg.jpeg',
    cardBTitle: 'Running clothing',
    cardBText: '',
    cardBAltText: 'A person runs along a trail.',
    cardBLink: '/c/running-clothes',
    cardCImage: 'https://www.rei.com/dam/20979698_crd-4-fitness-electronics-img-3_web_lg.jpeg',
    cardCTitle: 'Fitness electronics',
    cardCText: '',
    cardCAltText: 'The Garmin Instinct 3 Solar.',
    cardCLink: '/c/fitness-electronics',
  },
  {
    name: 'cold',
    altText: 'Three people on an evening run together.',
    eyebrow: null,
    lockup: {
      images: {
        xs: {
          caption: null,
          alt: 'HOKA, R E I Co-op, and Brooks',
          attribution: null,
          renditions: {
            original: {
              src: 'https://www.rei.com/dam/20978579_warm-weather-run-lockup-xs.svg',
              width: 159,
              height: 39,
            },
            wide: null,
            vertical: null,
          },
          imageType: 'svg',
        },
        sm: {
          caption: null,
          alt: 'HOKA, R E I Co-op, and Brooks',
          attribution: null,
          renditions: {
            original: {
              src: 'https://www.rei.com/dam/20978579_warm-weather-run-lockup-xs.svg',
              width: 159,
              height: 39,
            },
            wide: null,
            vertical: null,
          },
          imageType: 'svg',
        },
        md: {
          caption: null,
          alt: 'HOKA, R E I Co-op, and Brooks',
          attribution: null,
          renditions: {
            original: {
              src: 'https://www.rei.com/dam/20978577_warm-weather-run-lockup-lg.svg',
              width: 227,
              height: 55,
            },
            wide: null,
            vertical: null,
          },
          imageType: 'svg',
        },
        lg: {
          caption: null,
          alt: 'HOKA, R E I Co-op, and Brooks',
          attribution: null,
          renditions: {
            original: {
              src: 'https://www.rei.com/dam/20978577_warm-weather-run-lockup-lg.svg',
              width: 227,
              height: 55,
            },
            wide: null,
            vertical: null,
          },
          imageType: 'svg',
        },
      },
      alt: 'HOKA, R E I Co-op, and Brooks',
      useResizing: false,
    },
    lg: 'https://www.rei.com/dam/20989645_cold-weather-run-lead-img-lg_web_lg.jpeg',
    md: 'https://www.rei.com/dam/20989647_cold-weather-run-lead-img-md_web_lg.jpeg',
    sm: 'https://www.rei.com/dam/20989644_cold-weather-run-lead-img-sm_web_lg.jpeg',
    xs: 'https://www.rei.com/dam/20989650_cold-weather-run-lead-img-xs_web_lg.jpeg',
    title: 'Winter training starts here',
    text: 'We’ve got the gear, expertise and energy gels to get you there.',
    button: 'Shop running',
    link: '/c/running',
    cardAImage: 'https://www.rei.com/dam/20979702_crd-4-hoka-img-1_web_lg.jpeg',
    cardATitle: 'Running shoes',
    cardAText: '',
    cardAAltText: 'A person holds up a HOKA Bondi 9 for the camera.',
    cardALink: '/c/running-shoes',
    cardBImage: 'https://www.rei.com/dam/20989652_crd-4-cold-run-clothing-img-2_web_lg.jpeg',
    cardBTitle: 'Running clothing',
    cardBText: '',
    cardBAltText: 'A trio of people running together',
    cardBLink: '/c/running-clothes',
    cardCImage: 'https://www.rei.com/dam/20979698_crd-4-fitness-electronics-img-3_web_lg.jpeg',
    cardCTitle: 'Fitness electronics',
    cardCText: '',
    cardCAltText: 'The Garmin Instinct 3 Solar',
    cardCLink: '/c/fitness-electronics',
  },
];

export { createDataModel, activities };
