<script>
import HomepageLead from '../lead/HomepageLead.vue';
import { activities, createDataModel } from './utils';

export default {
  name: 'SeasonalityLeadComponent',
  components: {
    HomepageLead,
  },
  props: {
    activityId: { type: String, required: true, default: '' },
    leadAnalytics: { type: String, required: false, default: '' },
    leadCardAnalytics: { type: String, required: false, default: '' },
  },
  computed: {
    createData() {
      return {
        ...createDataModel(this.selectedActivity),
        leadAnalytics: this.leadAnalytics,
        leadCardAnalytics: this.leadCardAnalytics,
      };
    },
    selectedActivity() {
      return activities.find((activity) => activity.name === this.activityId);
    },
  },
};
</script>

<template>
  <homepage-lead
    v-if="selectedActivity"
    v-bind="createData"
  />
</template>

<style lang="scss">
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';
</style>
